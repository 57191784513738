.container {
  width: 1200px;
  .fieldLabel {
    .radioContainer {
      gap: 10%;
    }
    .radioContainerStatus {
      gap: 30.3%;
    }
  }
  .fieldLabel > p {
    width: 22%;
  }
  .fieldLabel > div {
    width: 38%;
  }
  .fieldText {
    color: var(--color-blue-gray);
    font-weight: 500;
    ::first-letter {
      text-transform: uppercase;
    }
  }

  .fieldLabelDuration {
    display: flex;
    align-items: center;
  }

  .fieldLabelDuration {
    .radioContainer {
      gap: 10%;
    }
    .radioContainerStatus {
      gap: 30.3%;
    }
  }
  .fieldLabelDuration > p {
    width: 22%;
  }
  .fieldLabelDuration > div {
    width: 38%;
    display: flex;
  }

  .fieldContentContainer {
    width: 350px;
    display: flex;
    gap: 10px;
  }

  .fieldLabelInfo {
    width: 200px;
    align-self: center;
  }
  .dateRange {
    width: 270px;
  }
  .gasAmount {
    width: 120px;
  }
  .continue {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
    font-weight: 500;
  }
  .attributesInput {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    .cohortDropdown {
      width: 320px;
    }
  }
  .removeCohort {
    align-self: center;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
  }
  .excludeAttribute {
    display: flex;
    width: 240px;
    align-items: center;
    padding-left: 20px;
    color: var(--color-blue-gray);
  }
  .numberStationSelected {
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: var(--color-purple);
    font-weight: 500;
  }
  .shoulderedBy {
    width: 60%;
  }
  .accountTypes {
    width: 400px;
  }
  .linkDetails {
    font-weight: 500;
    color: var(--color-purple);
    text-decoration: underline;
    cursor: pointer;
  }
  .usersWithoutThisAttribute,
  input {
    font-weight: 500;
    color: var(--color-blue-gray);
  }

  .volumeCap {
    width: 10rem;
  }
}

.container {
  width: 1350px;
  .fieldLabelGas {
    margin: unset;
  }
  .fieldLabel > p {
    width: 25%;
  }
  .fieldLabel > div {
    width: 35%;
  }
  .fieldFleetCard > div {
    width: 35%;
  }

  .fieldLabelLOCQPay > div {
    width: 12%;
  }

  .fieldPricingLabelLOCQPay > div:last-child {
    width: 20%;
  }

  .fieldPricingLabel > div:first-child {
    width: 12%;
  }
  .fieldPricingLabel > div:last-child {
    width: 87%;
  }

  .fieldPricingGasLabel > div:first-child {
    width: 25%;
    margin-top: 10px;
  }
  .fieldPricingGasLabel > div:last-child {
    width: 75%;
    margin-top: 10px;
  }

  .continue {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
  }
  .emailAddresses {
    width: 100%;
  }
  .fieldText {
    font-weight: 500;
  }
  .horizontalInput {
    display: flex;
    align-items: center;
    .gasTitle,
    .gasAmount {
      padding-left: 10px;
      width: 100%;
    }
  }

  .buttonHorizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    button:first-child {
      margin-right: 20px;
    }
    .stationCount {
      display: flex;
      align-items: center;
      span:first-child {
        margin-right: 5px;
      }
    }
    .numberStationSelected {
      display: flex;
      align-items: center;
      color: var(--color-purple);
      font-weight: 500;
    }

    .clearIcon {
      color: var(--color-indigo);
      font-size: 13px;
      cursor: pointer;
    }
  }
  .fieldPricingLabelPosition {
    margin-left: 164px;
    margin-bottom: -30px !important;
  }
  .pillEmailAddressesContainer {
    display: flex;
    flex-wrap: wrap;
    .emailAddressInPill {
      display: block;
      height: 40px;
      margin-right: 10px;
      margin-bottom: 5px;
      > span {
        padding: 5px 5px 5px 5px;
      }
      .active {
        color: var(--color-grass);
      }
      .pending {
        color: var(--color-cheddar);
      }
      .expired {
        color: var(--color-cement);
      }
      .deactivated {
        color: var(--color-deep-red);
      }
    }
  }
  .italic {
    font-style: italic;
  }
}
.editConfirmModal {
  width: 580px;
}
.onboardDate {
  span {
    font-size: 13px;
  }
}

.stationReference {
  display: flex;
  gap: 0px 5px;
  align-items: flex-end;
  div:nth-child(1) {
    width: 100%;
  }
  div:nth-child(2) {
    width: 80%;
  }
  .stationCount {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
  }

  .gasAmount {
    width: 100%;
  }

  .numberStationSelected {
    display: flex;
    align-items: center;
    color: var(--color-purple);
    font-weight: 500;
  }
  .removeCohort {
    cursor: pointer;
  }
  .noRemoveCohort {
    margin-left: 25px;
  }
}

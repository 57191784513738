.popoverContainer {
  :global {
    .MuiPaper-root {
      // margin-left: 70px;
      margin-left: -10px;
      overflow: initial;
      background: transparent;
      width: 300px;
    }
  }
  max-height: 200px;
  width: 300px;
}

.content {
  font-size: 14px;
  padding: 12px 23px;
  background: var(--color-white);
  border-radius: 4px;
  max-height: 200px;
  border: 1px solid #e4e5ec !important;
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  background: #ffffff;
  top: 10px;
  margin-right: -5px;
  transform: rotate(45deg);
  border-radius: 2px;
  border-right: 1px solid #e4e5ec !important;
  border-top: 1px solid #e4e5ec !important;
}

.icon {
  color: var(--color-night);
}

.table {
  td {
    padding: 10px 0px;
  }
  .label {
    padding-right: 30px;
  }
}

.image {
  width: 195px;
  border: solid 1px #ebeef7;
  border-radius: 8px;
  overflow: hidden;
  .src {
    width: 100%;
  }
  .photo {
    width: 100%;
    min-height: 100px;
    text-align: center;
  }
  .imageName {
    color: #4c69a4;
    text-align: center;
    padding: 3px 10px;
  }
}

.modal {
  background-color: transparent;
}

.viewPhoto {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.modalContent {
  text-align: center;
  width: 400px;
}

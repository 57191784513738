.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContent {
  width: 260px;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.search {
  width: 700px;
  margin-left: 10px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.chipItem {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-ash);

  :global {
    div:before,
    div:after {
      border-bottom: 0px !important;
    }
  }
}

.chipComponent {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}

.status {
  font-weight: 500;
}

.grass {
  color: var(--color-grass);
}

.cheddar {
  color: var(--color-cheddar);
}

.cement {
  color: var(--color-cement);
}

.deepRed {
  color: var(--color-deep-red);
}

.sky {
  color: var(--color-sky);
}

.desertstorm {
  color: var(--color-desertstorm);
}

.helperText {
  color: var(--color-grey-chateau);
  font-size: 12px;
}

.error {
  color: red;
  border-color: red;
}

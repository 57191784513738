.table {
  width: 400px;
  td {
    padding: 10px 0px;
  }
  .label {
    padding-right: 30px;
  }
}

.fieldLabelValue {
  margin-left: 40px;
}

.fieldInput {
  width: 100%;
  margin-left: 10px;
}

.fieldContent {
  width: 100%;
}

.fieldLabel {
  width: 250px;
}

.row {
  display: flex;
  flex-direction: row;
  button {
    margin-left: 20px;
    min-width: 100px;
  }
}

.right {
  float: right;
  button {
    min-width: 100px;
  }
}

.modal {
  margin-left: 48px;
  text-align: left;
}

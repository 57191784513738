.intro {
  margin-bottom: 30px;
}

.form {
  width: 400px;
  margin-bottom: 30px;

  > .subform {
    width: 260px;
  }
}

.continue {
  height: 39px;
  padding: 12px 40px;
}

.error {
  margin-top: -22px !important;
  font-size: 12px;
  color: var(--color-deep-red);
}

.stationReference {
  display: flex;
  gap: 0px 10px;
}

.numberStationSelected {
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: var(--color-purple);
  font-weight: 500;
}

.removeCohort {
  align-self: center;
  margin-left: 10px;
  padding-top: 5px;
  cursor: pointer;
}

.intro {
  margin-bottom: 30px;
}

.form {
  width: 500px;
  margin-bottom: 30px;

  > .subform {
    width: 60px;
  }
}

.continue {
  height: 39px;
  padding: 12px 40px;
}

.error {
  margin-top: -22px !important;
  font-size: 12px;
  color: var(--color-deep-red);
}
.input {
  padding-left: 30px;
  > p {
    width: 70%;
  }
}
.horizontalInput {
  width: 100%;
  display: flex;
  .mogasTitle,
  .mogasAmount {
    justify-content: space-between;
    }
    .mogasTitle {
      width:100%
    }
  .mogasAmount{width:200px}
  
}


.dateInput {
  width: 120%;
  display: flex;
  .dateTitle,
  .datePicker {
    justify-content: space-between;
    }
    .dateTitle {
      width:120%;
    }
    
     .datePicker{
      justify-content: space-between;
      width:300px;
  }
 
}
.actionButton {
  width: 120px}

.actionButtonBox {
  width: 120%;
  padding-left:5%;
  display: flex;}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContent {
  width: 260px;
}

.status {
  width: 15%;
  margin-right: 10px;
}

.search {
  width: 70%;
  margin-left: 10px;
}

.import {
  transform: rotate(180deg);
}

.downloadButton {
  margin-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  div {
    display: flex;
    justify-content: flex-end;
    margin-left: 15px;
  }
}

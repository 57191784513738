.fieldLabel {
  min-width: 250px;
}

.field {
  margin-left: 0;
}

.longField {
  min-width: 300px;
}

.businessName {
  margin-top: 8px;
}

.modal {
  width: 700px;
}

.container {
  text-align: left;
}
.content {
  font-size: 14px;
  color: var(--color-blue-gray);
  text-align: center;
  .dropZone {
    display: block;
    gap: 200px;
    padding-top: 40px;
    height: 256px;
    background-color: var(--color-light-cement);
    border: dashed var(--color-cement) 2px;
    border-radius: 4px;
  }
}
// .titleStyle {
//   color: var(--color-purple);
// }

.fileDetails {
  margin-top: 20px;
  margin-left: 0;
  color: var(--color-gray);
  > div {
    width: 50%;
  }
  .fileDetailsList {
    font-weight: 500px;
    color: var(--color-blue-gray);
  }
}

#header {
  .MuiMenuItem-root {
    font-size: 14px;
    color: var(--color-purple);
    font-weight: 500;
  }
}

.headerContainer {
  z-index: 111111111;
  height: 60px;
  padding: 9px 0px 9px 0px;
  box-shadow: 0 2px 6px 0 rgba(52, 56, 75, 0.08);
  background-image: linear-gradient(to right, var(--color-indigo) -9%, var(--color-purple));
  justify-content: center;
  position: fixed;
  .logoContainer {
    width: 196px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      margin-left: 15px;
      box-sizing: border-box;
      height: 29.4px;
    }
  }
  .toolbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 36px;
    padding-right: 36px;
    flex: 1;

    .title {
      font-size: 18px;
      font-weight: "bold";
    }

    .profile {
      width: 42px;
      height: 42px;
    }
  }
  .menuContainer {
    min-height: auto;
    height: auto;
  }
  .caretIcon {
    color: var(--color-white);
    font-size: 16px;
    margin-left: 8px;
  }
  .downloadIcon {
    color: var(--color-white);
    font-size: 24px;
    margin-left: 8px;
  }
}

.notificationIcon {
  background: #ee3b34;
  color: #ffffff;
  font-size: 10px;
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  margin-top: -25px;
  margin-right: -25px;
  position: absolute;
}

.menu {
  :global {
    .MuiPaper-root {
      top: 65px !important;
      right: 20px;
      left: auto !important;
    }
  }
}

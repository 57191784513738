.container {
  width: 1200px;
  .title {
    color: var(--color-night);
  }
  .fieldLabel {
    margin-left: 0;
    > p {
      width: 25%;
    }
    > div {
      width: 35%;
    }
  }
  .fieldText {
    color: var(--color-blue-gray);
    margin-right: 2%;
  }
  .amount {
    width: 150px;
  }
  .limitDisplay {
    display: flex;
    align-items: center;
    width: 60%;
    color: var(--color-blue-gray);
  }
  .redemptionLimitDisplay {
    width: auto;
    .redemptionLimitNumber {
      width: 100px;
      padding-right: 0px;
    }
    .redemptionLimitDetailsDisplay {
      margin-right: 5px;
    }
    .schedule {
      width: 270px;
    }
    .redeemLimitType {
      width: 470px;
      padding-right: 10px;
    }
  }
  .schedule {
    width: 98px;
  }
  .create {
    margin-top: 30px;
    height: 39px;
    padding: 12px 40px;
  }
}
.modalTitle {
  color: var(--color-night);
}
.modalContent p {
  color: var(--color-blue-gray);
}
.editConfirmModal {
  width: 580px;
}

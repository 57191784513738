.sidebarContainer {
  width: 196px;
  height: 100vh;
  position: fixed;
  z-index: 1111111;
  top: 30px;
  padding-top: 30px;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%);
  background-color: var(--color-white);

  .icon {
    transform: rotate(270deg);
    transition: transform 0.5s;
    color: var(--color-night);
    font-size: 10px;
  }
  .child {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sidebarList {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.expandIcon {
  font-size: 18px;
}

.listItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 42px;
  padding: 10px;
  text-decoration: none;
  .item {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .listIconRoot {
    min-width: 36px;
    display: flex;
    align-items: center;
    span {
      font-size: 24px !important;
      color: #babfe0;
    }
  }
  .listText {
    font-size: 12px;
    color: var(--color-night);
  }
}

.disableLink {
  pointer-events: none;
}

.selectedMenu {
  .listIconRoot {
    span {
      font-size: 24px !important;
      color: var(--color-indigo) !important;
    }
  }
  .listText {
    font-size: 12px;
    font-weight: 500 !important;
    color: black;
  }
}

.active {
  background: linear-gradient(to right, #44498d -9%, #753bbd);
  span {
    color: var(--color-white);
    font-weight: 700;
  }
  .listIconRoot span,
  .listText {
    color: #fff !important;
  }
}

:global {
  .MuiAccordionSummary-content {
    margin: 0px !important;
  }
  .MuiAccordionSummary-root {
    min-height: 38px !important;
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
  .MuiAccordionDetails-root {
    padding: 0px !important;
  }
}

.consumerSidebarContainer {
  width: 196px;
  height: 100vh;
  position: fixed;
  z-index: 1111111;
  top: 30px;
  padding-top: 30px;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 10%);

  background: linear-gradient(to bottom, #ffffff 7%, #f1f3fd);

  .icon {
    transform: rotate(270deg);
    transition: transform 0.5s;
    color: var(--color-blue-gray);
    font-size: 10px;
  }
  .child {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sidebarList {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

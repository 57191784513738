.products {
  display: flex;
  margin-bottom: 30px;
  > div {
    margin-right: 20px;
  }
}

.vertical {
  flex-direction: column;

  > div {
    width: 100%;
    margin-bottom: 20px;
  }
}

.intro {
  margin-bottom: 30px;
  .title {
    margin-top: 0;
    color: var(--color-night);
  }
  button span {
    font-weight: 500;
  }
  .primarySaveButton,
  .secondarySaveButton {
    height: 36px;
    width: 110px;
  }
}

.effectiveAt {
  margin: 10px 0 0 0;
  width: 200px;
}
.inputFile {
  margin: 20px 0 0 0;
}

.saveButton {
  margin: 20px 0 0 0;
}

.button {
  width: 200px;
}

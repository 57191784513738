.container {
  width: 600px;
  padding: 20px;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.cardContainer {
  margin: 30px;
  display: flex;
  max-height: 150px;
}

.cardImageContent {
  width: auto;
  display: flex;
  align-items: "center";
}

.logo {
  width: 30px;
  height: 30px;
}

.progressContainer {
  display: flex;
  width: 100%;
  align-items: center;

  .progressInnerContainer {
    width: 100%;
    margin: 10px 0px;
  }
}

.downloadButton {
  margin: 10px 0px;
}

.deleteIcon {
  color: var(--color-purple);
  font-size: 32px;
}

.circular {
  background: none;
  width: 20px !important;
  height: 20px !important;
}

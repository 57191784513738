.container {
  background: #f0effa;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: #34384b;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.product {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 70px;
}

.circle {
  font-size: 16px;
  margin-right: 8px;
}

.grass {
  color: var(--color-grass);
}

.cheddar {
  color: var(--color-cheddar);
}

.salsa {
  color: var(--color-salsa);
}

.deepBlue {
  color: var(--color-deep-blue);
}

.purple {
  color: var(--color-purple);
}

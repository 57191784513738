.autocomplete {
  width: 100%;

  :global {
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 38px;
    }

    input {
      font-weight: 500;
      font-size: 14px;
      height: 0px;
    }
    fieldset {
      border-color: rgba(0, 0, 0, 0.082);
    }
  }
}
.disabled {
  background-color: var(--color-ash);
}

.fontColor {
  input {
    color: var(--color-blue-gray);
  }
}

.error {
  :global {
    .MuiOutlinedInput-root {
      border: 1px solid red;
    }
  }
}

.multipleAutocomplete {
  width: 100%;

  :global {
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 38px;
    }

    .newInput {
      background-color: red;
    }

    selected {
      color: red;
    }
    .MuiChip-root,
    .MuiAutocomplete-tag,
    .MuiChip-deletable {
      margin-top: 0px;
      padding: 0px !important;
      border-radius: 4px;
      background-color: red;
      background: transparent;
      span {
        margin: 0px !important;
        padding: 0 8px 10px 0;
      }
      svg {
        margin: 0px !important;
        padding: 0 8px 10px 0;
        color: linear-gradient(to top, #44498d -9%, #753bbd);
      }
    }

    input {
      font-weight: 500;
      font-size: 14px;
      height: 0px;
    }
    fieldset {
      border-color: rgba(0, 0, 0, 0.082);
    }
  }
}
.selectedItems {
  text-transform: capitalize;
}
.disabled {
  background-color: var(--color-ash);
}

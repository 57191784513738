.container {
  width: 400px;
}

// .input,
// .total {
//   margin-top: 15px;
// }

.radio {
  margin-right: 70px;
}

.popoverContainer {
  :global {
    .MuiPaper-root {
      margin-left: 15px;
    }
  }
}

.content {
  font-family: "Roboto";
  font-size: 14px;
  padding: 12px 20px;
  &:hover {
    font-weight: 500;
    color: var(--color-purple);
    background: var(--color-baby-blue);
  }
}

.icon {
  color: var(--color-night);
}

.clickLabel {
  color: var(--color-purple);
  font-weight: bold;
  text-decoration: underline !important;
  cursor: pointer;
  font-size: 14x;
}

.container {
  display: flex;

  margin-top: 25px;

  &.right {
    text-align: right;
    width: 100%;
    justify-content: flex-end;
  }

  &.center {
    text-align: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    padding: 5px 20px;
    height: 36px;
    min-width: 110px;
    margin-left: 10px;
    margin-right: 10px;

    span {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.table {
  margin-top: 30px;
}

.italic {
  font-style: italic;
}

.subFulfilledDate,
.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: var(--color-grey-chateau);
  line-height: 1.5;
}

.filter {
  display: flex;
  align-items: center;
}

.search {
  width: 500px;
  margin-left: 10px;
}

.fieldContent {
  width: 250px;
}

.import {
  transform: rotate(180deg);
}

.button {
  width: 85px;
  background: var(--color-pale-purple);
  transition: background 0.5s;

  .text {
    color: var(--color-purple);
    font-weight: 500;
    align-items: center;
    display: flex;
  }

  .icon {
    font-size: 15px;
    margin-right: 10px;
  }

  &:hover,
  &.active {
    background: var(--color-purple);
    .text {
      color: var(--color-white);

      > .count {
        color: var(--color-purple);
        background: #eee0ff;
      }
    }
    .icon {
      font-size: 15px;
      margin-right: 10px;
      color: var(--color-white);
    }
  }
}

.upload {
  position: absolute;
  right: 0px;
}

.filterIcon {
  display: flex;
  align-items: center;
  span {
    font-size: 24px !important;
    color: inherit;
    margin: 0, auto;
  }
}

// .filterInput {
//   // padding-right: 20px;
//   // padding-left: 20px;
// }

.downloadButton {
  margin-right: 0px !important;
  display: flex;
  justify-content: flex-end;
  div {
    display: flex;
    justify-content: flex-end;
    margin-left: 15px;
  }
}

.button {
  background: #fff;
  transition: background 0.5s;

  .text {
    color: var(--color-purple);
    font-weight: 500;
    align-items: center;
    display: flex;
  }

  .icon {
    font-size: 15px;
    margin-right: 10px;
  }

  &:hover,
  &.active {
    background: var(--color-purple);
    .text {
      color: #350870;

      > .count {
        color: var(--color-purple);
        background: #eee0ff;
      }
    }
    .icon {
      font-size: 15px;
      margin-right: 10px;
      color: var(--color-white);
    }
  }
}

.filterContent {
  position: absolute;
  margin: 12px 0 0 10px;
  background: var(--color-white);
  padding: 15px 0 15px 0;
  box-shadow: 0 5px 5px 5px rgb(0 0 0 / 2%);
  border-radius: 5px;
  border: 1px solid var(--color-bright-gray);
  z-index: 2;

  &.inActive {
    display: none;
  }
}

.action {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  background-color: gray;
  opacity: 0.2;
  position: absolute;
  top: 0px;
  height: 100vh;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.count {
  border-radius: 20px;
  margin-left: 6px;
  background-color: #eee0ff;
  padding: 0px 9px;
  transition: background 0.5s;
}

.moreIcon {
  position: absolute;
  left: 10px;
  top: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  span {
    font-size: 24px !important;
  }
}

.icon {
  height: 30px;
  padding: 0%;
  width: 30px;
  margin-right: 15px;
}

.listItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 42px;
  padding: 25px 10px 25px 15px;
  text-decoration: none;
  cursor: pointer;

  &.active {
    background: linear-gradient(to right, #44498d -9%, #753bbd);
    span {
      color: var(--color-white);
      font-weight: 700;
    }
  }

  &:hover {
    background-color: var(--color-light-yellow);
    color: var(--color-dark-blue) span {
      color: var(--color-purple);
      font-weight: 700;
    }
  }
  .item {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .listIconRoot {
    min-width: 36px;
    display: flex;
    align-items: center;
    span {
      font-size: 24px !important;
      color: #babfe0;
    }
  }
  .listText {
    font-size: 12px;
    color: var(--color-night);
  }
}

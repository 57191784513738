.multipleSettlementCollection {
  display: flex;
  margin: 20px;
}

.fieldModalLabel {
  width: 160px;
  text-align: left;
}

.fieldModalHeading {
  width: 180px;
  text-align: left;
  color: var(--color-cement);
}

.modalContent {
  display: flex;
}

.fieldLabel {
  justify-content: flex-start;
  margin: 0 20px;
}

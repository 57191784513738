.container {
  background-color: var(--color-indigo);

  height: 50px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}

.text {
  color: var(--color-white);
  margin: auto;
}

.filters {
  margin-top: 30px;
}

.table {
  margin-top: 30px;
  table {
    width: 2000px;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.lowerText {
  color: var(--color-blue-ligth-gray);
  font-size: 12px;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

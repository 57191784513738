.modal {
  width: 550px;
}
.content {
  margin-left: 5px;
  font-size: 14px;
  .textStyle {
    color: var(--color-blue-gray);
    font-weight: 500;
  }
  .fieldLabel:nth-child(1) {
    > div:nth-child(1) {
      width: 38%;
    }
    > div:nth-child(2) {
      width: 63%;
    }
  }
  .fieldLabel {
    margin: 0 0 20px 0;
    > div:nth-child(1) {
      width: 35%;
    }
    > div:nth-child(2) {
      width: 65%;
    }
  }
  .dropdown {
    width: 312px;
  }
  .addCustomAttributeContainer {
    margin-top: 50px;
    .addCustomAttributeButton {
      width: 187px;
    }
  }
  .attributesInput {
    display: flex;
  }
  .removeCohort {
    align-self: center;
    margin-left: 115px;
    cursor: pointer;
  }
  .attributeContent {
    max-height: 300px;
    overflow-x: auto;
  }
}
.titleStyle {
  color: var(--color-night);
}

.accountName {
  display: flex;
  padding-top: 10px;
  gap: 0px 4px;
}

.filters {
  margin-top: 30px;
}

.table {
  margin-top: 30px;
}

.date {
  min-width: 100px;
}

.min120 {
  min-width: 120px;
}

.min100 {
  min-width: 100px;
}

.min80 {
  min-width: 80px;
}

.min50 {
  min-width: 50px;
}

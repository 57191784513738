.table {
  width: 400px;
  td {
    padding: 10px 0px;
  }
  .label {
    padding-right: 30px;
  }
}

.fieldLabelValue {
  margin-left: 40px;
}

.fieldInput {
  width: 100%;
  margin-left: 10px;
}

.reportContainer {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.switchBackground {
  background-color: var(--color-sky) !important;
}

.fieldLabel {
  justify-content: flex-start;
  align-items: flex-start;
}

.fieldContainer {
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0px;
}

.fieldLabelInfo {
  width: 184px;
}

.fieldLabelRemarks {
  width: 55px;
  margin-right: 20px;
}

.fieldLabelProductBreakdown {
  width: 75px;
}

.container {
  margin-top: 40x;

  .checkAll {
    display: flex;
    align-items: center;
    border-top: solid 1px var(--color-bright-gray);
    background-color: var(--color-dirty-white);
    padding: 1px 10px 1px 30px;
    .selectAllText {
      margin-left: 10px;
      font-weight: bold;
      color: var(--color-gray);
    }
  }

  .checkBoxContainer {
    margin: 10px 30px;
    .checkBox {
      display: flex;
      align-items: center;
      .typeText {
        margin-left: 10px;
        color: var(--color-blue-gray);
        font-weight: 500;
      }
    }
  }
}

.highlight {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: 12px 0px;
  border-radius: 4px;
}

.error {
  background-color: var(--color-blush);
}

.errorIcon {
  color: var(--color-salsa);
  margin-right: 10px;
}

.inputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

.inputFromTo {
  display: flex;
  align-items: center;
  border-color: var(--color-ash);
  height: 38px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  cursor: pointer;

  :global {
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      // background-color: #f0f8ff !important;
      background-color: var(--color-baby-blue) !important;
      color: var(--color-purple);
    }
    .DayPicker-Day {
      border-radius: 0 !important;
    }

    .DayPicker-Day.DayPicker-Day--today {
      color: #000000;
    }
    .DayPickerInput-Overlay {
      width: 600px;
      margin-left: -10px;
      margin-top: 7px;
      border-radius: 10px;
    }
    .DayPickerInput > input {
      border: unset;
      background-color: inherit;
      cursor: pointer;
      &::placeholder {
        color: #9f9f9f;
        font-weight: 500 !important;
        font-family: Roboto;
      }
    }
    .DayPickerInput > input:focus {
      outline: 0;
    }
  }
}

.startDate {
  width: 100%;
  :global {
    .DayPickerInput > input {
      width: 140%;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.icon {
  object-fit: unset;
  height: 20px;
  width: 20px;
}

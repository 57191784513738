.container {
  margin-top: 40x;

  .checkAll {
    display: flex;
    align-items: center;
    width: 100%;
    border-top: solid 1px var(--color-bright-gray);
    background-color: var(--color-dirty-white);
    margin: 0px;
    padding: 1px 20px 1px;
    .selectAllText {
      font-weight: bold;
      color: var(--color-gray);
    }
  }

  .checkBoxContainer {
    margin: 10px 20px 10px;
    .checkBoxProduct {
      display: flex;
      align-items: center;
      .gasText {
        color: var(--color-blue-gray);
        font-weight: 500;
      }
    }
  }
}

.modal {
  width: 600px;
  .titleStyle {
    color: var(--color-night);
  }
  .content {
    .table {
      max-height: 380px;
      overflow-x: auto;

      .stationListloading {
        padding-top: 30px;
        margin-bottom: 20px;
        border-top: solid 1px var(--color-light-grey-2);
        color: var(--color-night);
        font-weight: 500;
        min-height: 400px;
      }
    }
  }
  .textStyle {
    color: var(--color-blue-gray);
  }
  .filter {
    display: flex;
    align-items: center;
    width: 100%;
    .search {
      width: 100% !important;
      margin-left: 10px;
    }
    .stationCounterSection {
      width: 95%;
      text-align: end;
      align-items: center;
      margin-right: 0px;
    }

    .attributeSelectedStation {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 0px 10px;
      .removeCohort {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .stationCounter {
      font-weight: 500;
      color: var(--color-purple);
      text-decoration: underline;
    }
  }
  :global {
    .PrivateSwitchBase-root-5 {
      padding: 0px;
    }
  }
}

.circularLoader {
  margin: -50px 0px 0px 0px;
  background: rgb(255 255 255 / 92%);
  width: 100%;
}

.table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  > thead tr th {
    padding-left: 20px;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  > tbody {
    > tr:hover {
      background: var(--color-baby-blue);
    }
    > tr:first-child td:first-child {
      border-top-left-radius: 10px;
    }
    > tr:first-child td:last-child {
      border-top-right-radius: 10px;
    }
    > tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }
    > tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
    > tr:first-child > td {
      border-top: 1px solid var(--color-ash);
    }
    > tr > td:first-child {
      border-left: 1px solid var(--color-ash);
    }
    > tr > td {
      border-bottom: 1px solid var(--color-ash);
      // padding: 20px;
      padding: 10px 20px;
    }
    > tr > td:last-child {
      border-right: 1px solid var(--color-ash);
    }
  }
}

.headerText {
  color: var(--color-gray);
}

.content {
  color: var(--color-blue-gray);
}

.container {
  overflow-x: auto;
}

.lastColumnWithColumnPicker {
  display: flex;
  justify-content: flex-end;
}

.modifyColumn {
  width: 25px;
  padding-left: 10px;

  .modifyColumnIcon {
    font-size: 15px;
    transition: color 1s;
    &:hover {
      color: var(--color-indigo);
    }
  }
}

.modifyColumnContent,
.editColumnContent {
  max-height: 400px !important;
}

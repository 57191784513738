.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  
  .filter {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .fieldContent {
    width: 260px;
  }
  
  .status {
    width: 15%;
    margin-right: 10px;
  }
  
  .accountListStation {
    width: 15%;
    margin-right: 10px;
  }
  
  .search {
    width: 27%;
    margin-left: 10px;
  }
  
  .downloadButton {
    justify-content: flex-end;
    display: flex;
  }
  
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
  }

  .diesel {
    background-color: #e2b40d;
  }
  
  .gas91 {
    background-color: #10ac84;
  }
  
  .gas95 {
    background-color: #ee3b34;
  }
  
  .gas97 {
    background-color: #2e4491;
  }

  .lubricants {
    background-color: var(--color-purple);
  }
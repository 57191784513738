.filter {
  display: flex;
  align-items: center;
}

.search {
  width: 450px;
  margin-left: 10px;
}

.fieldContent {
  width: 260px;
}

.downloadButton {
  width: 70%;
  div {
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.productCode {
  margin-top: 20px;
}

// .filterInput {
//   padding-right: 20px;
//   padding-left: 20px;
// }

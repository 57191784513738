.container {
  width: 100%;

  .fieldLabel > div:first-child {
    width: 50%;
    padding-right: 5px;
  }
  .gasAmount {
    width: 25%;
  }

  .emailAddresses {
    width: 100%;
  }

  .resendStatus {
    font-weight: 500;
    color: var(--color-deep-red);
  }

  .newStatus {
    font-weight: 500;
    color: var(--color-grass);
  }

  .fieldText {
    font-weight: 500;
  }

  .pillEmailAddressesContainer {
    display: flex;
    max-width: 370px;
    flex-wrap: wrap;

    .emailAddressInPill {
      display: block;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }

  .capitalized::first-letter {
    text-transform: uppercase;
  }
}

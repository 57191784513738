.labelContainer {
  background: var(--color-whitesmoke);
  height: 38px;
  margin-left: -13px;
  border-radius: 3px 0px 0px 3px;
  border-right: 1px solid var(--color-ash);
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-night);
}

.disabled {
  color: var(--color-cement);
}

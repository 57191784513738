.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.imageSize {
  width: 58px;
  height: 68px;
  cursor: pointer;
}

.viewPhoto {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: auto;
  color: #95a2aa;
  line-height: 1.5;
}

.inputDiv{
  display: flex;
  justify-content: space-between;
}

.emailAddresses {
  width: 100%;
}

.inputDiv >  div:nth-child(1){
  display: flex;
  align-items: center;
}

.inputDiv >  div:nth-child(2){
  width: 70%;
}

.modal {
  width: 1250px;
  .titleStyle {
    color: var(--color-night);
  }
  .content {
    .table {
      max-height: 500px;
      overflow-x: auto;
    }
  }
  .textStyle {
    color: var(--color-blue-gray);
  }
  :global {
    .PrivateSwitchBase-root-5 {
      padding: 0px;
    }
  }
}

.circularLoader {
  margin: -50px 0px 0px 0px;
  background: rgb(255 255 255 / 92%);
  width: 100%;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}

.lubricants {
  background-color: var(--color-purple);
}

.lowerText {
  color: var(--color-blue-ligth-gray);
  font-size: 12px;
}

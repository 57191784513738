.image {
  width: 200px;
}

.container {
  text-align: center;
  margin-bottom: 30px;

  .title {
    margin-bottom: 5px;
  }
}

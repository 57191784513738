.container {
  width: 1200px;
  .title {
    color: var(--color-night);
  }
  .fieldLabel {
    > div:first-child {
      width: 25% !important;
    }
    > div {
      width: 40% !important;
    }
    margin-left: 0 !important;
  }
  .fieldText {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-blue-gray);
    margin-right: 2%;
    margin-bottom: 0 !important;
  }
  .onSearchAddContainer {
    display: flex;
    width: 400px;
    fieldset {
      border-radius: 5px 0 0 5px;
    }
    .viewModeSearch {
      fieldset {
        border-radius: 5px !important;
      }
    }
    .searchButton {
      height: 38px;
      border-radius: 0 5px 5px 0;
      margin-right: 5px;
    }
    .deleteButton {
      width: 10px;
    }
  }

  .duplicateCohortMsg {
    margin-top: 5px;
    .errorMsg {
      font-size: 12px;
      color: var(--color-salsa);
    }
  }

  .checkAllName {
    margin-top: 40px;
    margin-left: 21px;
    display: flex;
    align-items: center;
  }

  .cohortsListContainer {
    width: 400px;
    height: 335px;
    padding: 5px 20px;
    border-radius: 4px;
    border: solid 1px var(--color-bright-gray);
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 30px;
  }
  .checkboxes {
    display: block;
    .inputGroup {
      display: flex;
      align-items: center;
      margin-top: 7px;
    }
  }
  .noCohortsMsg {
    text-align: center;
    margin-top: 45%;
    color: var(--color-cement);
  }
}
.textCheckbox {
  color: var(--color-blue-gray);
}
.modalTitle {
  color: var(--color-night);
}
.modalContent p {
  color: var(--color-blue-gray);
}
.editConfirmModal {
  width: 580px;
}
.create {
  font-weight: 500;
  width: 110px;
}

.container {
  margin: 20px 0px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 10px;
}

.diesel {
  background-color: #e2b40d;
}

.gas91 {
  background-color: #10ac84;
}

.gas95 {
  background-color: #ee3b34;
}

.gas97 {
  background-color: #2e4491;
}
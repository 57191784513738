.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
  td {
    vertical-align: top;
  }
}

.subText {
  font-size: 12px;
}

.textStyle {
  color: var(--color-blue-gray);
}

.city {
  text-transform: capitalize;
  text-align: left;
}
.product {
  display: flex;
  flex-direction: column;
}

.introStyle {
  .subTitle {
    color: red;
  }
}

.inactiveStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 19px;

  border-radius: 10px;
  background-color: var(--color-deep-red);
  font-size: 10px;
  color: var(--color-white);
}

.activeStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 19px;

  border-radius: 10px;
  background-color: var(--color-grass);
  font-size: 10px;
  color: var(--color-white);
}

.actionIcon {
  cursor: pointer;
}

.upcoming {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--color-sky);
  font-size: 10px;
  color: var(--color-white);
}

.ongoing {
  background-color: var(--color-grass);
}

.ended {
  background-color: var(--color-cement);
}

.canceled {
  background-color: var(--color-deep-red);
}

.modal {
  width: 448px;
}
.content {
  font-size: 14px;
  color: var(--color-blue-gray);
  text-align: center;
}
.titleStyle {
  color: var(--color-night);
}

.fieldLabel {
  min-width: 150px;
}

.field {
  text-align: left;
  margin-left: 0;
  
}

.dropdown{
  width: 250px;
}

.longField{
  margin-left: 150px;
  width: 250px;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContent {
  width: 260px;
}

.status {
  width: 15%;
  margin-right: 10px;
}

.search {
  width: 40%;
  margin-left: 10px;
}

.fieldLabel {
  width: 150px;
}

.downloadButton {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination {
  margin-right: 30px;
}

.perPage {
  width: 128px;
}

.dropdown {
  height: 33px;
}

.pageSize {
  width: 135px;
}

.buttonNav {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
  &.disabled {
    opacity: 0.38;
  }
}

.table {
  width: 400px;
  td {
    padding: 10px 0px;
  }
  .label {
    padding-right: 30px;
  }
}

.fieldLabelValue {
  margin-left: 40px;
}

.fieldInput {
  width:100%;
  margin-left: 10px;
}

.fieldContent {
  width: 100%;
}

.fieldLabel {
  width: 250px;
}

.reportContainer {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.pdfContainer {
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 673px;
  margin-top: 15px;
  margin-bottom: 15px;
}
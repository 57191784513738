.container {
  text-align: center;
}

.title {
  margin-bottom: 5px;
}

.content {
  max-width: 470px;
  margin: auto;
}
.mainContainer{
  display:flex;
  margin-top:20px;
  flex-direction: column;
 
}
.subContainer{
  display:flex;
  width:400px;
  flex-direction: row;
  align-items: flex-start;

margin-top:15px;

  }
.category{
  align-items: flex-start;
  width:150px;
}
.buttonContainer{
  display:flex;
  justify-content: space-around;
  margin-top:50px;
}
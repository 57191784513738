.content {
  background-color: var(--color-white);
}
.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
  th {
    vertical-align: top;
  }
  td {
    vertical-align: top;
  }
}

.city {
  text-transform: capitalize;
  text-align: left;
}
.product {
  display: flex;
  flex-direction: column;
}

.introStyle {
  .subTitle {
    color: red;
  }
}

.inactiveStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 19px;

  border-radius: 10px;
  background-color: var(--color-deep-red);
  font-size: 10px;
  color: var(--color-white);
}

.activeStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 19px;

  border-radius: 10px;
  background-color: var(--color-grass);
  font-size: 10px;
  color: var(--color-white);
}

.actionIcon {
  cursor: pointer;
}

.titleStyle {
  color: var(--color-night);
  margin: 0;
  margin-bottom: 3px;
  font-size: 22px;
}

.textStyle {
  color: var(--color-blue-gray);
  margin: 0;
  margin-bottom: 3px;
  font-size: 14;
  font-weight: normal;
}

.upperText {
  color: var(--color-blue-gray);
  font-size: 14px;
}
.lowerText {
  color: var(--color-blue-ligth-gray);
  font-size: 12px;
}

.lowerCase {
  text-transform: lowercase;
}

.right {
  text-align: right;
  padding-right: 10px;
}
.bold {
  font-weight: 600;
}
.button {
  background: var(--color-pale-purple);
  transition: background 0.5s;

  color: var(--color-purple);
  font-weight: 900;
  align-items: center;
  display: flex;

  .icon {
    font-size: 15px;
    margin-right: 10px;
  }

  &:hover,
  &.active {
    background: var(--color-purple);
    color: var(--color-white);
    > .count {
      color: var(--color-purple);
      background: #eee0ff;
    }

    .icon {
      font-size: 15px;
      margin-right: 10px;
      color: var(--color-white);
    }
  }
}
.upcoming {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: var(--color-sky);
  font-size: 10px;
  color: var(--color-white);
}

.ongoing {
  background-color: var(--color-grass);
}

.ended {
  background-color: var(--color-cement);
}

.cancelled {
  background-color: var(--color-deep-red);
}

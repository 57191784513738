.filter {
  display: flex;
  align-items: center;
}

.date {
  margin-right: 10px;
  width: 30%;
}

.fieldContent {
  width: 240px;
}

.status {
  width: 15%;
  margin-right: 10px;
}

.content {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-bright-gray);
}

.search {
  width: 27%;
  margin-left: 10px;
}

.downloadButton {
  justify-content: flex-end;
  display: flex;
}

.fieldContent {
  width: 260px;
}

.field {
  width: 430px;
  margin: 20px 0px;
}

.fieldLabel {
  width: 160px;
}

.title {
  margin: 20px 0px;
}

.conversionTable {
  width: 730px;
  margin-bottom: 30px;
}
.smallField {
  width: 130px;
}

.row {
  margin: 10px 0px;
}

.column {
  display: flex;
  align-items: center;
  color: var(--color-cement);
}

.fullWidth {
  width: 100%;
  margin-right: 0px;
}

.info {
  margin-top: -16px;
}

.rowName {
  vertical-align: top;
  display: flow-root;
  padding-top: 18px !important;
}

.marginTop {
  margin-top: 10px;
}

.fieldRow {
  vertical-align: top;
  display: flow-root;
}

.table {
    margin-top: 30px;
  }
  
  .italic {
    font-style: italic;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
  }
  
  .diesel {
    background-color: #e2b40d;
  }
  
  .gas91 {
    background-color: #10ac84;
  }
  
  .gas95 {
    background-color: #ee3b34;
  }
  
  .gas97 {
    background-color: #2e4491;
  }

  .lubricants {
    background-color: var(--color-purple);
  }
  
  .subFulfilledDate,
  .subBusinessId {
    margin-top: 5px;
    font-size: 11px;
    width: 120px;
    color: var(--color-grey-chateau);
    line-height: 1.5;
    font-size: 12px;
  }
  
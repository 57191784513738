.list:not(:last-child) {
  &.horizontal {
    margin-right: 10px;
  }
  &.vertical {
    margin-bottom: 10px;
  }
}

.container {
  &.horizontal {
    display: flex;
  }
}

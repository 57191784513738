.container {
  margin-top: 20px;
  width: 100%;
  overflow: hidden;

  .showOnboardingCheckbox {
    margin: 40px 0px;
    margin-left: 21px;
    display: flex;
    align-items: center;
  }

  .fieldLabel {
    justify-content: flex-start;
  }

  .fieldFormLabel {
    justify-content: flex-start;
    margin: 0;
  }

  .fieldLabelInfo {
    width: 250px;
  }

  .fieldPricingLabel > div:first-child {
    width: 10%;
  }
  .fieldPricingLabel > div:last-child {
    width: 87%;
  }

  .horizontalInput {
    display: flex;
    align-items: flex-start;
    .gasTitle,
    .gasAmount {
      padding-left: 10px;
      width: 100%;
    }
  }

  .fieldPricingLabelPosition {
    margin-left: 10%;
  }

  .fieldContent {
    width: 250px;
  }

  .fieldContentLong {
    width: 400px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fieldTextInfo {
  text-align: left;
  margin-left: 15px;
  width: 180px;
}

.fieldText {
  text-align: left;
  width: 250px;
}

.fieldTextLabel {
  justify-content: flex-start;
}

.fieldTextContentLong {
  width: 250px;
  text-align: left;
}

.horizontalModalInput {
  display: flex;
  align-items: flex-start;
  .gasTitle,
  .gasAmount {
    padding-left: 10px;
    width: 100%;
  }
}

.buttonLink {
  border: none;
}

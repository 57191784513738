:root {
  --color-purple: #753bbd;
  --color-deep-blue: #2e4491;
  --color-blue-gray: #4c69a4;
  --color-blue-ligth-gray: #9bafd7;
  --color-baby-blue: #f0effa;
  --color-soft-blue: #babfdf;
  --color-sky: #1776f2;
  --color-indigo: #44498d;
  --color-deep-red: #de2f28;
  --color-salsa: #ee3b34;
  --color-grass: #019f3e;
  --color-emerald: #10ac84;
  --color-cheddar: #ffaa01;
  --color-gold: #e2b40d;
  --color-cement: #a1aeb7;
  --color-light-cement: #f7f8fa;
  --color-concrete: #f6f6f6;
  --color-whitesmoke: #f5f5f5;
  --color-ash: #e2e7ee;
  --color-grey-chateau: #95a2aa;
  --color-night: #34384b;
  --color-blush: #fdecea;
  --color-white: #ffffff;
  --color-dirty-white: #f9fafb;
  --color-desertstorm: #f3f2f1;
  --color-gray: #a9acc6;
  --color-bright-gray: #ebeef7;
  --color-pale-purple: #f8efff;
  --color-cyan: #02b2b8;
}

.small {
  height: 12px !important;
  width: 12px !important;
}

.medium {
  height: 16px !important;
  width: 16px !important;
}

.large {
  height: 25px !important;
  width: 25px !important;
}

.xlarge {
  height: 40px !important;
  width: 40px !important;
}

.black {
  path {
    fill: #000000 !important;
  }
}

.white {
  path {
    fill: #ffffff !important;
  }
}

.gray {
  path {
    fill: #667085 !important;
  }
}

.green {
  path {
    fill: #1db457 !important;
  }
}

.red {
  path {
    fill: #f04438 !important;
  }
}

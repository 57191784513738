.container {
  height: 38px;
  background: #f6f6f6;
  margin-left: -13px;
  left: 0px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  color: #34384b;
  font-size: 14px;
  font-weight: 500;
  min-width: 30px;
  border-right: solid 1px #e4e5ec;
  border-radius: 4px 0px 0px 4px;
}

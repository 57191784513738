.popoverContainer {
  :global {
    .MuiPaper-root {
      margin-left: 70px;
    }
  }
  max-height: 170px;
}

.content {
  font-size: 14px;
  padding: 12px 20px;
  background: var(--color-white);
}

.icon {
  color: var(--color-night);
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px 70px;
  .button {
    margin-right: 20px;
    border-radius: 50px;
    font-weight: 700;
    color: var(--color-gray);
  }
  text-decoration: none;
  .active {
    color: var(--color-purple);
  }
}

.navLink {
  font-weight: 700;
  color: var(--color-gray);
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 40px;
  border: solid 1px #ebeef7;
  font-size: 12px;
}

.activeNavLink {
  color: var(--color-purple) !important;
}

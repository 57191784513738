.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  .cancel {
    padding: 10px 30px;
  }
}

.modal {
  width: 418px;
}

.infoMessage {
  color: var(--color-salsa);
}

.content {
  margin-top: 10px;
}

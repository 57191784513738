.container {
  text-align: center;
}

.title {
  margin-bottom: 5px;
}

.content {
  max-width: 470px;
  margin: auto;
  color: black;
}

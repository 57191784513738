.container {
  border: solid 1px #ebeef7;
  border-radius: 8px;
}

.column {
  display: flex;
}

.columnHeader {
  height: 52px;
  padding: 10px;
}

.headerText {
  width: -webkit-fill-available;
  text-align: center;
}

.col {
  min-width: 100px !important;
  &:not(:last-child) {
    border-right: solid 1px #ebeef7;
  }
  transition: width 0.05s;
}

.grouper {
  overflow-x: hidden;

  &:not(.collapse) {
    max-width: 395px;
    width: auto;
  }

  &.collapse {
    max-width: 140px;
  }
}

.groupers {
  // max-width: 280px;
  overflow-x: auto;
}

.grouperItems {
  min-width: 138px;
}

.grouperItemsHeader {
  padding: 5px;
  align-items: center;
}

.button {
  margin-right: 10px;
}

.grouperItemsContent {
  display: flex;
  align-items: center;
  &.firstGrouperContent {
    padding-left: 40px;
  }
  &:not(.firstGrouperContent) {
    padding-left: 8px;
  }
}

.borderRight {
  &:not(:last-child) {
    border-right: solid 1px #ebeef7;
  }
}

.height {
  height: 40px;
}

.borderTop {
  border-top: solid 1px #ebeef7;
}

.borderTopInvi {
  border-top: solid 1px #ffffff;
}

.width115 {
  width: 115px;
}

.columnContent {
  padding: 10px;
  display: flex;
  align-items: center;
}

.highlightTotalBG {
  background: #f2f4fc;
}

.headerTextColor {
  color: #a9acc6;
  font-size: 12px;
}

.contentTextColor {
  color: #4c69a4;
  font-size: 14px;
}

.totalHighlightContent {
  border-top: 4px solid #ebeef7;
  // padding-top: 10px;
  align-items: flex-end;
  padding-bottom: 10px;
  font-weight: bold;
  height: 56px !important;
}

.container {
  text-align: center;
}
.image {
  width: 200px;
}

.title {
  margin-bottom: 5px;
}

.content {
  max-width: 320px;
  margin: auto;
}

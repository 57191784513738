.intro {
  margin-bottom: 30px;
  .title {
    margin-top: 0;
    color: var(--color-night);
  }
  button span {
    font-weight: 500;
  }
  .primaryEditButton {
    height: 36px;
    width: 70px;
  }
}

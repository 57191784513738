.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
}

.titleStyle {
  color: var(--color-night);
  margin: 0;
  margin-bottom: 3px;
  font-size: 22px !important;
}

.textStyle {
  color: var(--color-blue-gray);
  margin: 0;
  margin-bottom: 3px;
  font-size: 14;
  font-weight: normal;
}

.subText {
  margin-top: 5px;
  font-size: 14px;
  width: 120px;
  color: var(--color-blue-ligth-gray);
  line-height: 1.5;
}

.modalTitle {
  color: var(--color-night);
}

.modalContent p {
  color: var(--color-blue-gray);
}

.container {
  width: 500px;
  max-height: 500px;
  padding: 10px !important;
  .title {
    margin: 10px 0 0 10px;
    font-weight: bold;
    color: var(--color-night);
  }
  .table {
    margin-top: 20px;

    td {
      vertical-align: top;
    }
  }
  .circleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
  }
  .product {
    display: flex;
    flex-direction: column;
  }
  .textStyle {
    color: var(--color-blue-gray);
  }
}
.heading {
  color: var(--color-cement);
}

.options {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  box-shadow: 0.3px 0.3px 0.3px 2px rgb(0 0 0 / 5%);
  border-radius: 4px;
  display: none;
  position: absolute;
  z-index: 3;
  width: 300px;

  &.active {
    display: block !important;
  }

  .option {
    padding: 10px 20px;
    color: var(--color-night);
    display: flex;
    align-items: center;
    background-color: #fff;
    &:hover {
      background: var(--color-baby-blue) !important;
      color: var(--color-purple);
      font-weight: 500;
    }
    &.selected {
      background: var(--color-whitesmoke);
    }
  }
}

.circle {
  width: 15px !important;
  height: 15px !important;

  margin-right: 10px;
}

.empty {
  display: none;
}

.textfield {
  &.active {
    :global {
      .MuiInputBase-input:focus::placeholder {
        color: var(--color-night) !important;
        opacity: 0.5;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.selectedLength {
  height: 28px;
  background: #f5f6fa;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #753bbd;
  border-radius: 4px;
  font-weight: 500;
}

.selectedAll {
  color: #753bbd !important;
  font-weight: 500;
}

.removeIcon {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

.dimmer {
  position: fixed;
  // background: red;
  opacity: 0.1;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
}

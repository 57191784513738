.modal {
  width: 448px;
}
.title {
  color: var(--color-night);
}
.content {
  font-size: 14px;
  text-align: center;
}
.contentBody {
  color: var(--color-blue-gray);
}

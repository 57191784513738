.pill {
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 12px;
}

.xsmall {
  padding: 2px 4px;
  border-radius: 20px;
  font-size: 9px;
}

.grass {
  background-color: var(--color-grass);
  color: var(--color-white);
  font-weight: 500;
}

.cheddar {
  background-color: var(--color-cheddar);
  color: var(--color-white);
  font-weight: 500;
}

.cement {
  background-color: var(--color-cement);
  color: var(--color-white);
  font-weight: 500;
}

.deepRed {
  background-color: var(--color-deep-red);
  color: var(--color-white);
  font-weight: 500;
}

.sky {
  background-color: var(--color-sky);
  color: var(--color-white);
  font-weight: 500;
}

.desertstorm {
  background-color: var(--color-desertstorm);
  color: var(--color-night);
  font-weight: 500;
}

.cyan {
  background-color: var(--color-cyan);
  color: var(--color-white);
  font-weight: 500;
}

.container {
  margin: 20px 0px;
}

.subFulfilledDate,
.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.table {
  table {
    width: 1800px;
  }
}

.title {
  margin-bottom: 2px;
}

.content {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 10px;
  margin-left: -22px;
  margin-right: -22px;
}

.submit {
  margin-top: 20px;
}

.item {
  transition: background 1s;
  padding: 0 22px;
  &:hover {
    background: var(--color-baby-blue);
  }
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.search {
  width: 30%;
  margin-left: 10px;
}

.fieldContent {
  width: 260px;
}

.fieldControl {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.content {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-bright-gray);
}

// .filterInput {
//   padding-right: 20px;
//   padding-left: 20px;
// }

.downloadButton {
  margin-right: 0px !important;
  div {
    display: flex;
    justify-content: flex-end;
  }
}

.filters {
  margin-top: 37px;
}

.table {
  table {
    width: 1800px;
  }
  margin-top: 30px;
}

.subFulfilledDate,
.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.remarks {
  max-height: 160px;
  overflow-x: auto;
}

.filters {
  margin-top: 37px;
}

.table {
  margin-top: 30px;
}

.avatar {
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
}

.subFulfilledDate,
.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}
